<template>
  <input class="form-control" :id="inputId" :type="type" :max="max" :min="min" :step="step" :required="!notRequired" :disabled="disabled" :readonly="disabled" :placeholder="placeholder" :value="value" @input="onInput" />
</template>

<script>

import formInputTextMixin from './mixins/formInputText.js';

export default {
  mixins: [formInputTextMixin],
};

</script>
