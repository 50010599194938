import formInputFieldMixin from './formInputField.js';
import formInputTextPropsMixin from './formInputTextProps.js';

export default {
  mixins: [formInputFieldMixin, formInputTextPropsMixin],
  methods: {
    onInput (event) {
      this.$emit('input', event.target.value);
    },
  },
};
