import formInputFieldPropsMixin from './formInputFieldProps.js';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    max: {},
    min: {},
    step: {},
  },
};
