<template>
  <form class="form-container" @submit.prevent="$emit('submitForm', $event)" novalidate>
    <slot />
  </form>
</template>

<script>

export default {};

</script>

<style lang="scss">

.v-select {
  background: white;
}

</style>
